<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Campaign -->
        <template #cell(campaignEmail)="data">
            <b-badge pill :variant="data.item.campaignEmail?'light-success':'light-danger'">
                {{data.item.campaignEmail?'EVET':'HAYIT'}}
            </b-badge>
        </template>
        <template #cell(campaignSms)="data">
            <b-badge pill :variant="data.item.campaignSms?'light-success':'light-danger'">
                {{data.item.campaignSms?'EVET':'HAYIT'}}
            </b-badge>
        </template>
        <template #cell(campaignPhone)="data">
            <b-badge pill :variant="data.item.campaignPhone?'light-success':'light-danger'">
                {{data.item.campaignPhone?'EVET':'HAYIT'}}
            </b-badge>
        </template>
    </b-table>
    <!--Open Add User-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addUserPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Kullanıcı Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kullanıcı Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addUserRequest.firstName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Soyadı" label-for="surname">
                    <b-form-input name="lastName" id="surname" type="text" v-model="addUserRequest.lastName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Numarası" label-for="code">
                    <b-form-input id="code" type="text" v-model="addUserRequest.idNumber" />
                </b-form-group>

                <b-form-group label="E-Posta Adresi" label-for="email">
                    <b-form-input id="email" type="email" v-model="addUserRequest.email" />
                </b-form-group>

                <b-form-group label="Telefon Numarası" label-for="number">
                    <b-input-group>
                        <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                        <cleave id="phone" v-model="addUserRequest.gsmNumber" trim class="form-control" :raw="false" :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }" placeholder="5455948640" />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Şifre" label-for="password">
                    <b-form-input id="password" type="password" v-model="addUserRequest.password" />
                </b-form-group>

                <b-form-group label="Cinsiyesi" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(gender) => gender.name" label="name" :options="gender" v-model="addUserRequest.gender" />
                </b-form-group>
                <b-form-group label="Bilgilendirme" label-for="from">
                    <b-form-checkbox v-model="addUserRequest.campaignEmail">
                        E-Posta Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignSms">
                        Sms Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignPhone">
                        Aranarak Bilgilendirme
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addCompanyUser">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
    components: {
        Cleave,
        BFormCheckbox,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BInputGroupPrepend,
        BInputGroup,
        BTooltip
    },
    data() {
        return {
            gender: [{
                name: "ERKEK"
            }, {
                name: "KADIN"
            }, {
                name: "BELİRTİLMEMİŞ"
            }],
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'firstName', label: 'Adı' },
                { key: 'lastName', label: 'Soyadı' },
                { key: 'email', label: 'E-Posta Adresi' },
                { key: 'gsmNumber', label: 'Telefon Numarası' },
                { key: 'gender', label: 'Cinsiyet' },
                { key: 'campaignEmail', label: 'E-Posta B.' },
                { key: 'campaignSms', label: 'Sms B.' },
                { key: 'campaignPhone', label: 'Aranarak B.' },
            ],
            users: [],
            addUserPanel: false,
            addUserRequest: {
                branchId: 0,
                password: "",
                email: "",
                gsmNumber: "",
                firstName: "",
                lastName: "",
                gender: "",
                idNumber: "",
                campaignEmail: true,
                campaignSms: true,
                campaignPhone: true
            },
        }
    },
    created() {
        this.getData();
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addUserPanel = true;
        });
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Admin/Users");
            this.users = users.data.data;
        },
        myTrim(x) {
            var replaced = x.replace(/\s+/g, '');
            return replaced;
        },
        addCompanyUser() {
            var request = this.addUserRequest;

            this.$http.post("Admin/AddCompanyUser", {
                    password: request.password,
                    email: request.email,
                    gsmNumber: this.myTrim(request.gsmNumber),
                    firstName: request.firstName,
                    lastName: request.lastName,
                    gender: request.gender,
                    idNumber: request.idNumber,
                    campaignEmail: true,
                    campaignSms: true,
                    campaignPhone: true
                }).then((data) => {
                    this.getData();
                    this.addUserPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
